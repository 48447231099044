import React from "react";

export const common_strings = {
    en: {
        news_events: "News and Events",
        in: "in",
        all: "All",
        go_to_discover: 'Go to Discover',
        go_to: 'Go to ',
        processing_your_request: 'Processing your request',
        processing_payment: 'Processing payment...',
        open_motto: 'Find what\'s yours',
        back: 'Back',
        next_month: 'Next month',
        current_month: 'Current month',
        sign_in_title: 'Sign in to your account',
        register_title: 'Create new account',
        reset_title: 'Reset your password',
        token_title: 'Enter your recovery token',
        select: "Select",
        you: "You",
        additional_email_address: "Additional email address",
        client_since: "Client since",
        name: "Name",
        full_name: "Full name",
        email: "Email address",
        phone: 'Phone number',
        address: 'Address',
        id_number: 'Tax ID / ID Number',
        payment_method: 'Payment method',
        payment_method_expl: "Bank account number or credit card number",
        injury_info: 'Injuries',
        injury_info_expl: "Injury info is visible to the teachers in class overview",
        birthday: 'Birthday',
        note: 'Note',
        note_expl: 'A short note',
        notes: 'Notes',
        no_info: 'No info',
        search_or_add: 'Search or add',
        add_to_class: 'Add client',
        clear_search: 'Clear search',
        create_new: 'Create new',
        scan_qr: 'Scan',
        save: 'Save',
        address_expl: "Home address (for invoices)",
        day: "Day",
        week: "Week",
        month: "Month",
        six_months: "6 Months",
        year: "Year",
        cur_month: "Current month",
        close: "Close",
        continue: "Continue",
        cancel: "Cancel",
        search_results: "Search results",
        cancelled: "Cancelled",
        waiting: "Waiting List",
        export_data: "Export Data",
        send_message: "Send Message",
        message: "Message",
        send: "Send",
        view_profile: "View profile",
        back_to: "Back to",
        remove: "Remove",
        edit: "Edit",
        delete: "Delete",
        delete_account: "Delete account",
        every_week: "Every week",
        even_weeks: "Even weeks",
        odd_weeks: "Odd weeks",
        from: "From",
        to: "To",
        until: "Until",
        add: "Add",
        edit_profile: "Edit Profile",
        edit_logo: "Upload logo",
        read_more: "Read more",
        search: "Search",
        type_business: "Business/Teacher",
        type_client: "Client",
        payment_report: "Ticket sales by payment type",
        assign_rank: "Add client to group",
        assign_reward: "Add Reward",
        unassign_rank: "Remove client from group",
        rank_expl: "You can add clients to groups to add more structure to your client database. You can filter your database and reports and send messages to the groups. Group labels can be seen by your clients in the OPEN Client app.",
        reward_expl: "Rewards system is a motivational tool that allows you to manually or automatically assign progress badges to your clients. Build your own reward system, define rules and keep your clients engaged. Your clients will see their rewards in the Open Client app.",
        no_ranks_expl: "Looks like you haven't created Groups yet. Once you have at least one group you can add clients to it,use it in filters and send messages to the group members.",
        manage_ranks: "Manage groups",
        manage_rewards: "Manage rewards",
        group: "Group",
        add_new_rank: "Add new group",
        add_new_reward: "Add Reward",
        group_only: "Groups only",
        group_only_expl: "Only allow selected Group members to book classes of this type. You can manage your Groups in the Clients section.",
        select_groups: "Select groups",
        coupon_code: "Coupon code",
        show_all: "Show all",
        show_advanced: "Show advanced filters",
        hide_advanced: "Hide advanced filters",
        navigation: {
            dashboard: "Dashboard",
            profile: "Business profile",
            client_overview: "Client overview",
            report: "Report",
            reports: "Reports",
            logout: "Logout",
            schedule: "Schedule",
            all_classes: "All Classes",
            cabinet: "Cabinet",
            clients: "Clients",
            prev_day: "Previous day",
            next_day: "Next day",
            next_week: "Next week",
            prev_week: "Previous week",
            today: "Today",
            earlier: "Earlier",
            tomorrow: "Tomorrow",
            later: "Soon",
            go_to_profile: "Go to Profile",
            support: "Support",
            switch_business: "Switch business",
            documentation: "Documentation",
            mobile_app: "Download app",
            how_to_use: "How to use OPEN",
            download_open_app: "Download OPEN App",
            manage_subscription_expl: "You can manage your plan in Business Profile",
            edit_resources: "Edit resources",
            add_delete: "Add/Delete",
            schedule_app_expl: "* To get a better overview of your schedule please download the app or use OPEN from your desktop.",
            discover: "Discover",
            upcoming_classes: "Upcoming classes",
        },
        short_day: {
            mon: "Mon",
            tue: "Tue",
            wed: "Wed",
            thu: "Thu",
            fri: "Fri",
            sat: "Sat",
            sun: "Sun",
        },
        business: {
            business_type: "Business type",
            name: "Business/Teacher name",
            web: "Web address",
            city: "City",
            zipcode: "Postal code",
            country: "Country",
            main_locale: "Main locale",
            locale: "Locale",
            all_locales: "All Locales",
            locales: "Locales",
            locale_expl: "Locale is a place where you offer your services. If you run your business in several places you might want to create additional locales. Each locale can contain several rooms.",
            location: "Location",
            business_description_hint: "Short description of your business that will be visible to your clients in the OPEN Client app.",
            business_description: "Business description",
            yoga_studio: "Yoga studio",
            gym: "Gym",
            pilates: "Pilates",
            dance_studio: "Dance studio",
            school: "School",
            team: "Team",
            ma: "Martial Arts",
            basketball: "Basketball",
            teacher: "Teacher",
            teacher_locale: "Center teacher",
            admin_locale: "Center administrator",
            admin: "Administrator",
            currency: "Currency",
            time_format: "Time format",
            use_reminders: "Use payment reminders",
            use_reminders_expl: "If enabled you will get prompted to create an unpaid class reminder each time when a client without a ticket gets registered to the class.",
        },
        messages: {
            no_messages: "There are no messages here yet. Please note that all messages older than 1 year are getting deleted automatically.",
            messages_expl: "Send and receive messages from {0}.",
            view_messages: "View",
            messages: "Messages",
            close: "Close",
        },
        description: "Description",
        add_more: "Add more",
        ad_reqs: "Please add a banner image, title, description and dates before saving.",
        link: "Link",
        bio: "Bio",
        bio_expl: "Will be visible to your clients in the Open Client app",
        click_to_edit: "Click to edit",
        back_to_profile: "Back to Profile",
        prev: "Previous",
        next: "Next",
        date: "Date",
        date_end: "End date",
        time: "Time",
        salary: "Salary",
        send_message_hint: "Please add an email address to send messages",
        required: "Required",
        optional: "Optional",
        see_all: "See all",
        login: "Sign in",
        exit: "Exit",
        register: "Register",
        forgot_password: "Forgot password?",
        have_token: "I have my token already",
        reset_password: "Reset password",
        password: "Password",
        new_password: "New password",
        agree_with: "I agree with the",
        privacy_policy: "Privacy Policy",
        privacy_policy_simple: "Privacy Policy",
        create_account: "Create account",
        send_reset_token: "Send reset token",
        send_reset_token_expl: "You will receive your password reset token at your email address",
        enter_token: 'Enter your recovery token (check your email and spam folder)',
        reset_password_expl: "If provided token is valid your password will be reset and you will be redirected to your dashboard",
        reset_token: "Reset token",
        token: "Token",
        sign_in_expl: "Sign in with your Open account",
        open_expl_login: "Open Manager is a space for gym and fitness studio managers and teachers. Manage your resources, handle bookings and get insights on every aspect of your business.",
        welcome_to_open: "Welcome to OPEN",
        client_account_warning: "You are trying to login with your Open Client account. Please use OPEN Client app to sign in or create a new business profile.",
        open: {
            why_open: "At OPEN we believe that we live in the beautiful era\n" +
                "\n" +
                "So many things are optimised by machines for us, that we finally have a chance to focus on things we love. Times when management of even a small business required all your time and attention are over. Now we can rely on our small yet powerful helpers that are always by our hand and let us be better at what we do and not at computers or paper work.\n" +
                "\n" +
                "\n" +
                "You might remember the times when new business software implementation at a company meant several days of training and constant monitoring and dedicated workforce afterwords. Things are different now.\n" +
                "\n" +
                "\n" +
                "We know that Time is one of the most valuable things in our life. That's why we focus on saving as much of it for you as we can. Through automation and continuous learning.\n" +
                "\n" +
                "\n" +
                "We also know that every gym and studio are unique and we are we are ready to learn from each of you. We don't offer you a rigid set of features, we offer you an opportunity to get exactly what you need. We are open to you.",
            subscriptions_title: "Use Open for free or choose one of our plans",
            subscriptions_expl: "We offer a free limited usage and three plans to choose from.",
            about_open_link: "About OPEN",
            client_app_link: "OPEN Client app",
            welcome_text_1: "Thank you for choosing OPEN to help you manage your business!",
            welcome_text_2: "To start using OPEN we have to complete your Business Profile by creating Class Types, Ticket Types and Teacher profiles. These are the main resources that will help you manage your business and keep track of all activities.",
            download_client_app: "Download OPEN Client app to book classes and stay in touch with your gym.",
        },
        tutorial: {
            how_to_start: {
                title: "How to start",
                body_1: "To start using OPEN we have to complete your",
                body_2: "by creating",
                body_3: "profiles. These are the main resources that will help you manage your business and keep track of all activities.",
            },
            class_types: {
                title: "Class Types",
                body_1: "Class Types are templates for the classes that you will be adding to your schedule. They contain information like class title, duration, class appearance in the OPEN Client app and ",
                body_1_1: "Rules",
                body_2: ". You can also configure which tickets will be accepted for the classes of this type and create a list of ",
                body_3: "regular clients",
                body_4: ". Once you’ve created at least one class type you can navigate to the Schedule and start adding classes.",
            },
            rules: {
                title: "Rules",
                body_1: "The most powerful feature of Class types is Rules. If you mark any class type as regular you’ll be able to add rules to it. Rules define when classes should appear in your schedule along with teacher and room configuration. Setting up rules is as easy as setting up an alarm on your mobile. Once you’ve added at least one rule you can navigate to the Schedule and start adding classes for any period of time.",
            },
            regular_clients: {
                title: "Regular clients",
                body_1: "If you add a list of regular clients to a class type they will be automatically added to all future classes of this type. This can save time and help manage group classes.",
            },
            ticket_types: {
                body_1: "Ticket types contain descriptions of the tickets that you sell to your clients. All the information except its name can be adjusted at the moment of ticket registration. You can set the maximum number of ticket usage on a per-day or per-week basis. Tickets marked as Subscription will be automatically renewed one day before it expires.",
            },
            teachers: {
                body_1: "Teachers are the professionals that work with you and can be assigned to classes in your schedule. Teacher profile includes a salary calculator and an overview of the teacher’s classes for the selected period of time. Teachers marked as Public will be also visible in your profile in the",
                body_2: "OPEN Client",
                body_3: " along with the photo and bio.",
            },
            rooms: {
                body_1: "Rooms can be assigned to classes either directly or through the ",
                body_2: "Rules ",
                body_3: "in the class types settings. Rooms are places where you give classes and help your clients in the ",
                body_4: "OPEN Client app ",
                body_5: "and you can filter your schedule. Rooms are also important if you want to prevent overbooking or use the Waiting list feature in OPEN Client app. Both features depend on the room capacity property.",
            },
            pp: {
                body_1: "In order to get listed in the OPEN Client app you have to complete your public profile and turn the Public switch on. If all required fields are properly filled your business will appear in the OPEN Client app shortly after confirmation."
            },
            online_tickets: {
                title: "Online ticket sales",
                body_1: "OPEN offers you an opportunity to sell tickets to your clients via the ",
                body_2: "OPEN Client app ",
                body_3: "with credit and debit cards. Once a ticket is purchased by your client it gets automatically registered in your database and can be used for registration to the classes. In order to use this feature you have to connect your ",
                body_4: "Stripe ",
                body_5: "account to OPEN in Public Profile. Stripe is a payment processing platform that handles card payments. Please note that there’s a fee of 3.5% per transaction that will be subtracted from the payments. You can find estimated charges for each ticket type in the Online Payments section. If a ticket type is not marked as Public it won’t be visible in the OPEN Client app to your clients.",
            },
            client_app: {
                title: "Client app",
                body_1: "Open Client app offers you an opportunity to better connect with your clients. In the client app they can get the latest updates of your schedule, get notified about the upcoming events, purchase tickets and book classes or join waiting lists. All Client app related settings you can find in Business Profile -> Public Profile.",
                body_2: "iOS App",
                body_3: "Android App",
            },
            memberships: {
                title: "Client Memberships",
                body_1: "Membership is a subscription service that you can sell to your clients through the OPEN Client app. Membership price will be automatically charged from your client credit card on monthly basis starting with the day of purchase. When you create a Memberships it will appear in your Stripe dashboard under Products. You can find all purchased memberships in your Stripe dashboard under Customers -> Subscriptions where you can make refunds, subscription cancellations and even pause the charges temporarily. Every Stripe transaction (like Membership purchase) comes with a fee calculated based on your region and type of Credit Card used for purchase and usually is between 1.4% + €0.25 and 2.9% + €0.25. You can find more information about Stripe services and fees by visiting www.stripe.com. In order to start creating your Memberships you have to connect your Stripe account to OPEN. This will allows us to process the payments on your behalf.",
            }
        },
        notifications: {
            booking_title: "New booking: {0} @ {1}",
            unbooking_title: "Booking cancelled: {0} @ {1}",
            new_client_title: "New client: {0}",
            new_wix_ticket_title: "WIX ticket: {0}",
            new_stripe_ticket_title: "STRIPE ticket: {0}",
            new_feedback: "New feedback for: {0}",
        },
        cookies_warning: "This website uses cookies to enhance the user experience.",
        cookies_ok: "I understand",
        scanning_expl: "You can scan your client QR-codes to quickly register them to classes instead of typing in their names. You can use any external scanner device compatible with your computer. Scanner must work in the Keyboard mode (HID-KBW). Your clients can find their QR-codes in the welcome email, OPEN Client app or you can send it to them from the Client profile page.",
        scan_state_scanning: "Listening...",
        scan_state_processing: "Processing...",
        scan_state_scanning_expl: "Scanning in Progress (please make sure your scanner is connected to the computer)",
        wrong_qr_code: "QR-code wasn't recognized",
        send_qr_to_client: "Send QR-code",
        send_qr_to_client_expl: "Your clients can register to your classes with a QR-code. They can find their QR-codes in the welcome email, OPEN Client app or you can send it to them by pressing SEND button.",
        settings: "Settings",
        send_welcome_email: "Automatically send Welcome Email to clients",
        send_welcome_email_expl: "When you create a new client record or update your client email address, they receive an email with the invitation code for the registration in the OPEN Client app and a QR code for the quick check-in at your classes.",
        send_exp_ticket_email: "Automatically send expiring ticket alerts to clients",
        send_exp_ticket_email_expl: "Your clients will get notified about the expiration of their tickets.",
        welcome_email_custom_message: "Custom message",
        welcome_email_custom_message_expl: "You can add a short message here and it will be included in the welcome email.",
        verification_sent: "An email with verification link was sent to your email address. If you don't receive it please make sure your email address is correctly spelled and can receive emails or contact OPEN support.",
        select_all: "Select all",
        deselect_all: "Clear all",
        buy: "Buy",
        feedback: "Feedback",
        teacher_sessions: "Teacher shifts",
        feedback_expl: "Your clients can leave their feedback to your classes though the Open Client app.",
        teacher_sessions_expl: "Teachers can scan their QR-codes in the Check-In section to log the start and the end of the working hours. Here you will find reports on the Teacher working hours.",
        copy: "Copy",
        message_sent: "You message was successfully sent",
        enabled: "Enabled",
        contacts: "Contacts",
        getInTouch: "Get in Touch",
        home: "Home",
        client_profile_updates: "Changes made here will only affect your client profile. Your business profile and/or teacher profile won't get updated.",
        purchase_ticket: "Purchase Ticket",
        confirm_cancellation: "Do you want to cancel your reservation?",
    },
    es: {
        in: "en",
        news_events: "Noticias y Eventos",
        all: "Todos",
        go_to_discover: 'Ir a Descubrir',
        go_to: 'Ir a ',
        processing_your_request: 'Tramitación de su petición...',
        processing_payment: 'Procesamiento del pago...',
        open_motto: 'Encuentra lo que es tuyo',
        back: 'Volver',
        next_month: 'Próximo mes',
        current_month: 'Mes en curso',
        sign_in_title: 'Acceda a su cuenta',
        register_title: 'Crear una nueva cuenta',
        reset_title: 'Restablecer la contraseña',
        token_title: 'Introduzca su token de recuperación',
        select: "Seleccione",
        you: "Tú",
        additional_email_address: "Dirección de correo electrónico adicional",
        client_since: "Cliente desde",
        name: "Nombre",
        full_name: "Nombre completo",
        email: "Correo electrónico",
        phone: 'Número de teléfono',
        address: 'Dirección',
        id_number: 'Identificación fiscal / Número de ID',
        payment_method: 'Método de pago',
        payment_method_expl: "Número de cuenta bancaria o de tarjeta de crédito",
        injury_info: 'Lesiones',
        injury_info_expl: "La información de las lesiones es visible para los profesores en la visión general de la clase",
        birthday: 'Cumpleaños',
        note: 'Nota',
        note_expl: 'Una breve nota',
        notes: 'Notas',
        no_info: 'Sin información',
        search_or_add: 'Buscar o añadir',
        add_to_class: 'Añadir cliente',
        clear_search: 'Despejen la búsqueda',
        create_new: 'Crear un nuevo',
        scan_qr: 'Escanear',
        save: 'Guardar',
        address_expl: "Dirección (para las facturas)",
        day: "Día",
        week: "Semana",
        month: "Mes",
        six_months: "6 Meses",
        year: "Año",
        cur_month: "El mes actual",
        close: "Cerrar",
        continue: "Continúa",
        cancel: "Cancelar",
        search_results: "Resultados de la búsqueda",
        cancelled: "Cancelado",
        waiting: "Lista de espera",
        export_data: "Exportar datos",
        send_message: "Enviar mensaje",
        message: "Mensaje",
        send: "Enviar",
        view_profile: "Ver perfil",
        back_to: "Regreso a",
        remove: "Quitar",
        edit: "Editar",
        delete: "Eliminar",
        delete_account: "Eliminar cuenta",
        every_week: "Cada semana",
        even_weeks: "Semanas pares",
        odd_weeks: "Semanas impares",
        from: "De",
        to: "Hasta",
        until: "Hasta",
        add: "Añadir",
        edit_profile: "Editar Perfil",
        edit_logo: "Subir el logo",
        read_more: "Leer más...",
        type_business: "Negocio/Profesor(a)",
        type_client: "Cliente",
        payment_report: "Venta de tickets por tipo de pago",
        assign_rank: "Asignar cliente a grupo",
        assign_reward: "Agregar una Recompensa",
        unassign_rank: "Quitar al cliente del grupo",
        rank_expl: "Puedes añadir clientes a los grupos para añadir más estructura a tu base de datos de clientes. Puedes filtrar tu base de datos e informes y enviar mensajes a los grupos. Las etiquetas de los grupos pueden ser vistas por tus clientes en la aplicación OPEN Client.",
        reward_expl: "El sistema de recompensas es una herramienta de motivación que le permite asignar manualmente o automáticamente insignias de progreso a sus clientes. Construya su propio sistema de recompensas, defina las reglas y mantenga a sus clientes comprometidos. Tus clientes verán sus recompensas en la aplicación Open Client.",
        no_ranks_expl: "Parece que aún no has creado grupos. Una vez que tengas al menos un grupo puedes añadir clientes a él, usarlo en los filtros y enviar mensajes a los miembros del grupo.",
        manage_ranks: "Manejar grupos",
        manage_rewards: "Manejar las recompensas",
        group: "Grupo",
        add_new_rank: "Añadir nuevo grupo",
        add_new_reward: "Añade la recompensa",
        group_only: "Sólo para grupos",
        group_only_expl: "Sólo permite a miembros del Grupos seleccionados reservar clases de este tipo. Puedes gestionar tus Grupos en la sección Clientes.",
        select_groups: "Seleccione grupos",
        coupon_code: "Código de cupón",
        show_all: "Mostrar todos",
        search: "Buscar",
        show_advanced: "Mostrar filtros avanzados",
        hide_advanced: "Ocultar filtros avanzados",
        navigation: {
            dashboard: "Tablero de mandos",
            profile: "Perfil de la empresa",
            client_overview: "Vista general del cliente",
            report: "Informe",
            reports: "Informes",
            logout: "Cierre de sesión",
            schedule: "Horario",
            all_classes: "Todas las clases",
            cabinet: "Gestió",
            clients: "Clientes",
            prev_day: "Día anterior",
            next_day: "Día siguiente",
            next_week: "Próxima semana",
            prev_week: "Semana anterior",
            today: "Hoy",
            earlier: "Anteriormente",
            tomorrow: "Mañana",
            later: "Pronto",
            go_to_profile: "Ir a Perfil",
            support: "Soporte",
            switch_business: "Cambiar de negocio",
            documentation: "Documentación",
            mobile_app: "Descargar aplicación",
            how_to_use: "Cómo usar OPEN",
            download_open_app: "Descargar OPEN App",
            manage_subscription_expl: "Puedes manejar tu plan en Perfil de Negocio",
            edit_resources: "Editar recursos",
            add_delete: "Añadir/Borrar",
            schedule_app_expl: "* Para tener una mejor visión de tu horario, por favor descargue la aplicación o use OPEN desde tu ordenador.",
            discover: "búsqueda",
            upcoming_classes: "Próximas clases",
        },
        short_day: {
            mon: "Lun",
            tue: "Mar",
            wed: "Mie",
            thu: "Jue",
            fri: "Vie",
            sat: "Sab",
            sun: "Dom",
        },
        business: {
            business_type: "Tipo de negocio",
            name: "Nombre de la empresa/del profesor",
            web: "Dirección web",
            city: "Ciudad",
            zipcode: "Código postal",
            country: "País",
            main_locale: "Centro principal",
            locale: "Centro",
            all_locales: "Todos los centros",
            locales: "Centros",
            locale_expl: "Centro es un lugar donde ofreces tus servicios. Si tienes tu negocio en varios lugares, tal vez quieras crear Centros adicionales. Cada Centro puede contener varias salas.",
            location: "Ubicación",
            business_description_hint: "Breve descripción de tu negocio que será visible para tus clientes en la aplicación OPEN Client.",
            business_description: "Descripción del negocio",
            yoga_studio: "Estudio de Yoga",
            gym: "Gimnasio",
            pilates: "Estudio de Pilates",
            dance_studio: "Escuela de baile",
            school: "Escuela",
            team: "Equipo",
            ma: "Artes marciales",
            teacher: "Profesor(a)",
            basketball: "Baloncesto",
            teacher_locale: "Profesor(a) del centro",
            admin_locale: "Administrador(a) del Centro",
            admin: "Administrador(a)",
            currency: "Moneda",
            time_format: "Formato de tiempo",
            use_reminders: "Usar recordatorios de pago",
            use_reminders_expl: "Si está activado, se le pedirá que cree un recordatorio de clase sin pagar cada vez que un cliente sin ticket se registre en la clase.",
        },
        messages: {
            no_messages: "No hay mensajes aquí todavía. Tenga en cuenta que todos los mensajes de más de 1 año se eliminan automáticamente.",
            messages_expl: "Envía y recibe mensajes de {0}.",
            view_messages: "Ver",
            messages: "Mensajes",
            close: "Cerrar",
        },
        description: "Descripción",
        add_more: "Añadir más",
        ad_reqs: "Por favor, añada una imagen, título, descripción y fechas antes de guardar.",
        link: "Enlace",
        bio: "Biografía",
        bio_expl: "Será visible para tus clientes en la aplicación Open Client",
        click_to_edit: "Haz clic para editar",
        back_to_profile: "Volver a Perfil",
        prev: "Anterior",
        next: "Siguiente",
        date: "Fecha",
        date_end: "Fecha de finalización",
        time: "Hora",
        salary: "Salario",
        send_message_hint: "Por favor, añada una dirección de correo electrónico para enviar mensajes",
        required: "Obligatorio",
        optional: "Opcional",
        see_all: "Ver todos",
        login: "Iniciar sesión",
        exit: "Detener sesión",
        register: "Registrarse",
        forgot_password: "¿Olvidó la contraseña?",
        have_token: "Ya tengo mi token",
        reset_password: "Restablecer la contraseña",
        password: "Contraseña",
        new_password: "Nueva contraseña",
        agree_with: "Estoy de acuerdo con la",
        privacy_policy: "Política de privacidad",
        privacy_policy_simple: "Política de privacidad",
        create_account: "Crear cuenta",
        send_reset_token: "Enviar ficha de reinicio",
        send_reset_token_expl: "Recibirás tu token de restablecimiento de contraseña en tu dirección de correo electrónico",
        enter_token: 'Introduce tu token de recuperación (comprueba tu correo electrónico y la carpeta de spam)',
        reset_password_expl: "Si la ficha proporcionada es válida, tu contraseña será restablecida y serás redirigido a tu tablero de mandos",
        reset_token: "Token de restablecimiento",
        token: "Token",
        sign_in_expl: "Inicie sesión con su cuenta OPEN",
        open_expl_login: "Open Manager es un espacio para los administradores y profesores de los estudios de fitness y los gimnasios. Gestiona tus recursos, maneja las reservas y obtén información sobre cada aspecto de tu negocio.",
        welcome_to_open: "Bienvenido a OPEN",
        client_account_warning: "Estás intentando iniciar sesión con tu cuenta de Open Client. Por favor, utilice la aplicación OPEN Client para iniciar sesión o crear un nuevo perfil de negocio.",
        open: {
            why_open: "En OPEN creemos que vivimos en la hermosa era",
            subscriptions_title: "Use Open for free or choose one of our plans",
            subscriptions_expl: "We offer a free limited usage and three plans to choose from.",
            about_open_link: "Acerca de OPEN",
            client_app_link: "OPEN Client app",
            welcome_text_1: "¡Gracias por elegir a OPEN para ayudarte a manejar tu negocio!",
            welcome_text_2: "Para empezar a usar OPEN tenemos que completar tu perfil de negocio creando tipos de clases, tipos de tickets y perfiles de profesores(as). Estos son los principales recursos que te ayudarán a gestionar tu negocio y a llevar un seguimiento de todas las actividades.",
            download_client_app: "Descarga la aplicación OPEN Client para reservar clases y estar en contacto con tu gimnasio.",
        },
        tutorial: {
            how_to_start: {
                title: "Cómo empezar",
                body_1: "Para empezar a usar OPEN tenemos que completar tu",
                body_2: "creando",
                body_3: ". Estos son los principales recursos que le ayudarán a gestionar tu negocio y a hacer un seguimiento de todas las actividades.",
            },
            class_types: {
                title: "Tipos de clases",
                body_1: "Los tipos de clase son plantillas para las clases que se añadirán a tu horario. Contienen información como el título de la clase, la duración, la apariencia de la clase en la aplicación OPEN Client y las ",
                body_1_1: "Reglas",
                body_2: ". También puedes configurar qué tickets serán aceptados para las clases de este tipo y crear una lista de ",
                body_3: "clientes habituales",
                body_4: ". Una vez que hayas creado al menos un tipo de clase, podrás navegar al Horario y empezar a añadir clases.",
            },
            rules: {
                title: "Reglas",
                body_1: "La característica más poderosa de los tipos de clase son las reglas. Si marcas cualquier tipo de clase como regular podrás añadirle reglas. Las reglas definen cuándo deben aparecer las clases en tu horario junto con la configuración del profesor y la sala. Configurar las reglas es tan fácil como configurar una alarma en tu móvil. Una vez que hayas añadido al menos una regla, podrás navegar hasta el horario y empezar a añadir clases para cualquier período de tiempo.",
            },
            regular_clients: {
                title: "Clientes habituales",
                body_1: "Si añades una lista de clientes habituales a un tipo de clase, se añadirán automáticamente a todas las futuras clases de este tipo. Esto puede ahorrar tiempo y ayudar a gestionar las clases de grupo.",
            },
            ticket_types: {
                body_1: "Los tipos de entradas contienen descripciones de las entradas que vendes a tus clientes. Toda la información, excepto su nombre, puede ser ajustada en el momento del registro de la entrada. Puede establecer el número máximo de uso de los boletos por día o por semana. Las entradas marcadas como Suscripción se renovarán automáticamente un día antes de su vencimiento.",
            },
            teachers: {
                body_1: "Los Profesores son los profesionales que trabajan con tu y pueden ser asignados a clases en tu horario. El perfil del profesor incluye una calculadora de salario y una visión general de las clases del profesor para el período de tiempo seleccionado. Los profesores marcados como Públicos serán visibles en tu perfil en la",
                body_2: "aplicación OPEN Client",
                body_3: " junto con la foto y la biografía.",
            },
            rooms: {
                body_1: "Las Salas pueden asignarse a las clases directamente o a través de las ",
                body_2: "Reglas",
                body_3: " en los ajustes de los tipos de clase. Las salas son lugares donde se dan clases y se ayuda a los clientes en la",
                body_4: " aplicación OPEN Client ",
                body_5: " y se puede filtrar el horario. Las salas también son importantes si quieres evitar el exceso de reservas o utilizar la función de lista de espera en la aplicación OPEN Client. Ambas características dependen de la propiedad de la capacidad de la sala.",
            },
            pp: {
                body_1: "Para aparecer en la aplicación OPEN Client tienes que completar tu perfil público. Si todos los campos obligatorios están correctamente rellenados, tu negocio aparecerá en la aplicación OPEN Client poco después de la confirmación."
            },
            online_tickets: {
                title: "Venta de tickets en línea",
                body_1: "OPEN le ofrece la oportunidad de vender entradas a sus clientes a través de la ",
                body_2: "aplicación OPEN Client ",
                body_3: "con tarjetas de crédito y débito. Una vez que el cliente compra un ticket, éste queda registrado automáticamente en tu base de datos y puede ser usado para registrarse en las clases. Para poder utilizar esta función tienes que conectar tu cuenta ",
                body_4: "Stripe ",
                body_5: " con OPEN en el Perfil Público. Stripe es una plataforma de procesamiento de pagos que se encarga de los pagos con tarjeta. Por favor, tenga en cuenta que hay una tarifa del 3,5% por transacción que se restará de los pagos. Puede encontrar los cargos estimados para cada tipo de ticket en la sección de Pagos en línea. Si un tipo de ticket no está marcado como Público no será visible en la aplicación OPEN Client para tus clientes.",
            },
            client_app: {
                title: "Client app",
                body_1: "La aplicación Open Client te ofrece la oportunidad de conectarte mejor con tus clientes. En la aplicación de cliente pueden obtener las últimas actualizaciones de tu horario, ser notificados sobre los próximos eventos, comprar tickets y reservar clases o unirse a las listas de espera. Todos los ajustes relacionados con la aplicación de cliente los puedes encontrar en Perfil de Negocio -> Perfil Público.",
                body_2: "iOS App",
                body_3: "Android App",
            },
            memberships: {
                title: "Las membresías",
                body_1: "La membresía es un servicio de suscripción que puedes vender a tus clientes a través de la aplicación OPEN Client. El precio de la membresía será cargado automáticamente de su tarjeta de crédito en forma mensual a partir del día de la compra. Cuando cree una Membresía aparecerá en tu Tablero de Control de Stripe bajo Productos. Puedes encontrar todas las membresías compradas en tu Tablero de Control de Stripe bajo Clientes -> Suscripciones donde puedes hacer reembolsos, cancelaciones de suscripción e incluso pausar los cargos temporalmente. Cada transacción de Stripe (como la compra de una membresía) viene con una tarifa calculada en base a su región y el tipo de tarjeta de crédito utilizada para la compra y usualmente es entre 1.4% + 0.25 y 2.9% + 0.25. Puedes encontrar más información sobre los servicios y tarifas de Stripe en www.stripe.com. Para empezar a crear tus membresías tienes que conectar tu cuenta de Stripe a OPEN. Esto nos permitirá procesar los pagos en tu nombre.",
            }
        },
        notifications: {
            booking_title: "Nueva reserva: {0} @ {1}",
            unbooking_title: "Reserva cancelada: {0} @ {1}",
            new_client_title: "Nuevo cliente: {0}",
            new_wix_ticket_title: "WIX ticket: {0}",
            new_stripe_ticket_title: "STRIPE ticket: {0}",
            new_feedback: "Nuevo feedback para: {0}",
        },
        cookies_warning: "Este sitio web utiliza cookies para mejorar la experiencia del usuario.",
        cookies_ok: "Vale",
        scanning_expl: "Puede escanear los códigos QR de su clientes para registrarlos rápidamente en las clases en lugar de escribir sus nombres. Puede usar cualquier dispositivo de escaneo externo compatible con su ordenador. El escáner debe funcionar en el modo de teclado (HID-KBW). Sus clientes pueden encontrar sus QR-codes en el correo electrónico de bienvenida, en la aplicación OPEN Client o puede enviárselos desde la página de perfil de cliente.",
        scan_state_scanning: "Escuchando...",
        scan_state_processing: "Procesando...",
        scan_state_scanning_expl: "Escaneo en progreso (por favor, asegúrese de que su escáner está conectado a la computadora)",
        wrong_qr_code: "El código QR no fue reconocido",
        send_qr_to_client: "Envíar el código QR",
        send_qr_to_client_expl: "Sus clientes pueden registrarse en las clases con un código QR. Pueden encontrar sus QR-codes en el correo electrónico de bienvenida, en la aplicación OPEN Client o pueden enviárselo pulsando el botón ENVIAR.",
        settings: "Ajustes",
        send_welcome_email: "Enviar automáticamente un correo electrónico de bienvenida a los clientes",
        send_welcome_email_expl: "Cuando creas un nuevo registro de clientes o actualizas su dirección de correo electrónico, reciben un correo electrónico con el código de invitación para el registro en la aplicación OPEN Client y un código QR para el registro rápido en tus clases.",
        send_exp_ticket_email: "Envía automáticamente alertas de tickets que expiran a los clientes",
        send_exp_ticket_email_expl: "Sus clientes serán notificados sobre la expiración de sus tickets.",
        welcome_email_custom_message: "Mensaje personalizado",
        welcome_email_custom_message_expl: "Puedes añadir un mensaje corto aquí y se incluirá en el correo electrónico de bienvenida.",
        verification_sent: "Un correo electrónico con un enlace de verificación fue enviado a su dirección de correo electrónico. Si no lo recibes, por favor asegúrate de que tu dirección de correo electrónico esté correctamente escrita y puedas recibir correos electrónicos o contactar con el soporte de OPEN.",
        select_all: "Seleccionar todos",
        deselect_all: "Despejar todos",
        buy: "Comprar",
        feedback: "Comentarios",
        teacher_sessions: "Turnos de profesores",
        feedback_expl: "Sus clientes pueden dejar sus comentarios en sus clases a través de la aplicación Open Client.",
        teacher_sessions_expl: "Los profesores pueden escanear sus códigos QR en la sección de Check-In para registrar el inicio y el final de las horas de trabajo. Aquí encontrará informes sobre las horas de trabajo de los profesores.",
        copy: "Copiar",
        message_sent: "Su mensaje fue enviado con éxito",
        enabled: "Activado",
        contacts: "Contactos",
        getInTouch: "Ponte en contacto",
        home: "Inicio",
        client_profile_updates: "Los cambios realizados aquí sólo afectarán a su perfil de cliente. Su perfil de empresa y/o de profesor no se actualizarán.",
        purchase_ticket: "Comprar un ticket",
        confirm_cancellation: "¿Quieres cancelar tu reserva?",
    },
    ru: {
        news_events: "Новости и события",
        in: "в",
        all: "Все",
        go_to_discover: 'Перейти к началу',
        go_to: 'Перейти к ',
        processing_your_request: 'Обработка вашего запроса...',
        processing_payment: 'Обработка платежа...',
        open_motto: 'Найди своё',
        back: 'Назад',
        next_month: 'Следующий месяц',
        current_month: 'Текущий месяц',
        sign_in_title: 'Войдите в свой аккаунт',
        register_title: 'Создать новый аккаунт',
        reset_title: 'Восстановить пароль',
        token_title: 'Введите токен восстановления',
        select: "Выберите",
        you: "Вы",
        additional_email_address: "Дополнительный имейл",
        client_since: "Зарегестрирован",
        name: "Имя",
        full_name: "Полное имя",
        email: "е-мэйл",
        phone: 'Номер телефона',
        address: 'Адрес',
        id_number: 'номер ID',
        payment_method: 'способ оплаты',
        payment_method_expl: "Номер банковского счета или кредитной карты",
        injury_info: 'Травмы',
        injury_info_expl: "Информация о травмах видна учителям в обзоре занятий",
        birthday: 'День рождения',
        note: 'Примечание',
        note_expl: 'Краткая записка',
        notes: 'Примечания',
        no_info: 'Нет информации',
        search_or_add: 'Поиск или добавление',
        add_to_class: 'Добавить клиента',
        clear_search: 'Очистить поиск',
        create_new: 'Создать нового',
        scan_qr: 'Сканировать',
        save: 'Сохранить',
        address_expl: "Домашний адрес (для счетов)",
        day: "День",
        week: "Неделя(ю)",
        month: "Месяц",
        six_months: "6 Месяцев",
        year: "Год",
        cur_month: "Текущий месяц",
        close: "Закрыть",
        continue: "Продолжить",
        cancel: "Отмена",
        search_results: "Результаты поиска",
        cancelled: "Отменено",
        waiting: "Список ожидания",
        export_data: "Экспортировать данные",
        send_message: "Отправить сообщение",
        message: "Сообщение",
        send: "Отправить",
        view_profile: "Просмотр профиля",
        back_to: "Назад к",
        remove: "Удалить",
        edit: "Редактирование",
        delete: "Удалить",
        delete_account: "Удалить аккаунт",
        every_week: "Каждую неделю",
        even_weeks: "Чётные недели",
        odd_weeks: "Нечётные недели",
        from: "С",
        to: "До",
        until: "До",
        add: "Добавить",
        edit_profile: "Редактировать профиль",
        edit_logo: "Загрузить логотип",
        read_more: "Подробнее",
        search: "Поиск",
        type_business: "Бизнес/Учитель",
        type_client: "Клиент",
        payment_report: "Продажа абонементов по типу оплаты",
        assign_rank: "Добавить клиента в группу",
        assign_reward: "Добавить Достижение",
        unassign_rank: "Удалить клиента из группы",
        rank_expl: "Вы можете добавлять клиентов в группы, чтобы добавить больше структуры в вашу клиентскую базу данных. Вы можете фильтровать свою базу данных и отчеты, а также отправлять сообщения группам. Ваши клиенты могут видеть к каким группам они пранадлежат в приложении OPEN Client.",
        reward_expl: "Система Достижений - это инструмент мотивации, позволяющий вручную или автоматически назначать значки прогресса своим клиентам. Постройте свою собственную систему поощрений, определите правила и поддерживайте вовлеченность клиентов. Ваши клиенты увидят свои награды в приложении Open Клиент.",
        no_ranks_expl: "Похоже, вы еще не создали группы. Как только у вас будет хотя бы одна группа, вы сможете добавлять в нее клиентов, использовать ее в фильтрах и отправлять сообщения членам группы.",
        manage_ranks: "Управление группами",
        manage_rewards: "Управление достижениями",
        group: "Группа",
        add_new_rank: "Добавить новую группу",
        add_new_reward: "Добавить Достижение",
        group_only: "Только для групп",
        group_only_expl: "Разрешите только членам выбранных групп регистрироваться на классы этого типа. Вы можете управлять группами в разделе Клиенты.",
        select_groups: "Выбор групп",
        coupon_code: "Код купона",
        show_all: "Показать всех",
        show_advanced: "Показать дополнительные фильтры",
        hide_advanced: "Спрятать дополнительные фильтры",
        navigation: {
            dashboard: "Панель управления",
            profile: "Бизнес-профиль",
            client_overview: "Обзор клиента",
            report: "Отчет",
            reports: "Отчеты",
            logout: "Выход из системы",
            schedule: "Расписание",
            all_classes: "Все занятия",
            cabinet: "Кабинет",
            clients: "Клиенты",
            prev_day: "Предыдущий день",
            next_day: "Следующий день",
            next_week: "Следующая неделя",
            prev_week: "Предыдущая неделя",
            today: "Сегодня",
            earlier: "Ранее",
            tomorrow: "Завтра",
            later: "Скоро",
            go_to_profile: "Перейти к профилю",
            support: "Поддержка",
            switch_business: "Сменить бизнес",
            documentation: "Документация",
            mobile_app: "Скачать приложение",
            how_to_use: "Как использовать OPEN",
            download_open_app: "Скачать приложение OPEN",
            manage_subscription_expl: "Вы можете настроить свой план в Профиле бизнеса",
            edit_resources: "Редактирование ресурсов",
            add_delete: "Добавить/Удалить",
            schedule_app_expl: "* Чтобы получить более полное представление о вашем расписании, пожалуйста, загрузите приложение или используйте OPEN с вашего компьютера.",
            discover: "Поиск",
            upcoming_classes: "Ближайшие занятия",
        },
        short_day: {
            mon: "Пн.",
            tue: "Вт.",
            wed: "Ср.",
            thu: "Чт.",
            fri: "Пт.",
            sat: "Сб.",
            sun: "Вс.",
        },
        business: {
            business_type: "Вид деятельности",
            name: "Название компании / Имя учителя",
            web: "веб-адрес",
            city: "Город",
            zipcode: "Почтовый индекс",
            country: "Страна",
            main_locale: "Основной центр",
            locale: "Центр",
            all_locales: "Все центры",
            locales: "Центры",
            locale_expl: "Центр - это место, где вы предлагаете свои услуги. Если Вы ведете свой бизнес в нескольких местах, вы можете создать дополнительные центры. В каждом центре может быть несколько комнат.",
            location: "Местоположение",
            business_description_hint: "Краткое описание вашего бизнеса, которое будет видно вашим клиентам в приложении OPEN Client.",
            business_description: "Описание бизнеса",
            yoga_studio: "Студия йоги",
            gym: "Спортзал",
            pilates: "Пилатес",
            dance_studio: "Танцевальная студия",
            school: "Школа",
            team: "Команда",
            ma: "Боевые искусства",
            basketball: "Баскетбол",
            teacher: "Учитель",
            teacher_locale: "Учитель центра",
            admin_locale: "Администратор центра",
            admin: "Администратор",
            currency: "Валюта",
            time_format: "Формат времени",
            use_reminders: "Использовать напоминания об оплате",
            use_reminders_expl: "Если эта функция включена, то каждый раз, когда клиент без абонемента регистрируется в классе, вам будет предложено создать напоминание об оплате.",
        },
        messages: {
            no_messages: "Здесь пока нет сообщений. Обратите внимание, что все сообщения старше 1 года удаляются автоматически.",
            messages_expl: "Отправляйте и получайте сообщения от {0}.",
            view_messages: "Открыть",
            messages: "Сообщения",
            close: "Закрыть",
        },
        description: "Описание",
        add_more: "Добавить ещё",
        ad_reqs: "Пожалуйста, добавьте изображение, название, описание и даты перед сохранением.",
        link: "Ссылка",
        bio: "Биография",
        bio_expl: "Будет виден вашим клиентам в приложении OPEN Клиент.",
        click_to_edit: "Нажмите, чтобы редактировать",
        back_to_profile: "Назад в профиль",
        prev: "Предыдущий",
        next: "Следующий",
        date: "Дата",
        date_end: "Дата завершения",
        time: "Время",
        salary: "Зарплата",
        send_message_hint: "Пожалуйста, добавьте адрес электронной почты для отправки сообщений",
        required: "Обязательное",
        optional: "Необязательное",
        see_all: "Посмотреть все",
        login: "Войти",
        exit: "Выход",
        register: "Зарегистрироваться",
        forgot_password: "Забыли пароль?",
        have_token: "У меня уже есть токен",
        reset_password: "Смена пароля",
        password: "Пароль",
        new_password: "Новый пароль",
        agree_with: "Я согласен(на) с",
        privacy_policy: "Политикой конфиденциальности",
        privacy_policy_simple: "Политика конфиденциальности",
        create_account: "Создать аккаунт",
        send_reset_token: "Отправить токен сброса пароля",
        send_reset_token_expl: "Вы получите токен сброса пароля на ваш электронный адрес.",
        enter_token: 'Введите токен восстановления (проверьте электронную почту и папку спама).',
        reset_password_expl: "Если предоставленный токен будет действителен, Ваш пароль будет сменен, и Вы будете перенаправлены на панель управления.",
        reset_token: "Токен сброса пароля",
        token: "Токен",
        sign_in_expl: "Войти в систему со своим аккаунтом OPEN",
        open_expl_login: "Open Manager - это пространство для менеджеров и учителей спортзалов и фитнес-студий. Управляйте своими ресурсами и резервациями и следите за каждым аспектом вашего бизнеса.",
        welcome_to_open: "Добро пожаловать в OPEN",
        client_account_warning: "Вы пытаетесь войти в систему со своим клиентским аккаунтом. Пожалуйста, используйте приложение OPEN Клиент, чтобы войти в систему или создайте новый бизнес-профиль.",
        open: {
            why_open: "At OPEN we believe that we live in the beautiful era\n" +
                "So many things are optimised by machines for us, that we finally have a chance to focus on things we love. Times when management of even a small business required all your time and attention are over. Now we can rely on our small yet powerful helpers that are always by our hand and let us be better at what we do and not at computers or paper work.\n" +
                "\n" +
                "\n" +
                "You might remember the times when new business software implementation at a company meant several days of training and constant monitoring and dedicated workforce afterwords. Things are different now.\n" +
                "\n" +
                "\n" +
                "We know that Time is one of the most valuable things in our life. That's why we focus on saving as much of it for you as we can. Through automation and continuous learning.\n" +
                "\n" +
                "\n" +
                "We also know that every gym and studio are unique and we are we are ready to learn from each of you. We don't offer you a rigid set of features, we offer you an opportunity to get exactly what you need. We are open to you.",
            subscriptions_title: "Use Open for free or choose one of our plans",
            subscriptions_expl: "We offer a free limited usage and three plans to choose from.",
            about_open_link: "Об OPEN",
            client_app_link: "Приложение OPEN Клиент",
            welcome_text_1: "Спасибо, что выбрали OPEN, чтобы помочь вам управлять вашим бизнесом!",
            welcome_text_2: "Чтобы начать использовать OPEN, мы должны заполнить ваш бизнес-профиль, создав профили классов, абонементов и учителей. Это основные ресурсы, которые помогут вам управлять вашим бизнесом и отслеживать все виды активности.",
            download_client_app: "Скачайте приложение OPEN Клиент, чтобы бронировать занятия и поддерживать связь со своим спортзалом.",
        },
        tutorial: {
            how_to_start: {
                title: "Как начать",
                body_1: "Чтобы начать использовать OPEN, мы должны завершить ваш",
                body_2: "создав",
                body_3: ". Это основные ресурсы, которые помогут вам управлять своим бизнесом и отслеживать все виды деятельности.",
            },
            class_types: {
                title: "Типы классов",
                body_1: "Типы классов - это шаблоны для классов, которые вы будете добавлять в свое расписание. Они содержат такую информацию, как название класса, продолжительность, отображение класса в приложении OPEN Client и ",
                body_1_1: "Правила",
                body_2: ". Вы также можете настроить, какие абонементы будут приниматься для классов этого типа, и создать список ",
                body_3: "постоянных клиентов",
                body_4: ". После того, как вы создали хотя бы один тип класса, вы можете перейти в Расписание и начать добавлять классы.",
            },
            rules: {
                title: "Правила",
                body_1: "Самой полезной возможностью Типов Классов являются Правила. Если вы пометите любой тип класса как Регулярный, то сможете добавлять к нему правила. Правила определяют, когда классы должны появиться в вашем расписании вместе с конфигурацией учителя и комнаты. Настройка правил так же проста, как и настройка будильника на вашем мобильном телефоне. После того, как вы добавили хотя бы одно правило, вы можете перейти в Расписание и начать добавлять классы на любой период времени.",
            },
            regular_clients: {
                title: "Постоянные клиенты",
                body_1: "Если вы добавите список постоянных клиентов к типу класса, то они будут автоматически добавлены ко всем будущим классам этого типа. Это позволит сэкономить время и поможет управлять групповыми классами.",
            },
            ticket_types: {
                body_1: "Типы абонементов содержат описания абонементов, которые вы продаете своим клиентам. Вся информация, кроме ее названия, может быть скорректирована в момент регистрации абонемента. Вы можете установить максимальное количество использования абонемента в день или в неделю. Абонементы, отмеченные как Подписка, будут автоматически продлены за день до истечения срока действия.",
            },
            teachers: {
                body_1: "Учителя - это профессионалы, которые работают с вами и могут быть назначены на занятия в вашем расписании. Профиль учителя включает в себя калькулятор заработной платы и обзор занятий учителя за выбранный период времени. Учителя, помеченные как Публичные, также будут видны в вашем профиле в ",
                body_2: "приложении OPEN Клиент",
                body_3: " с биографией и фото.",
            },
            rooms: {
                body_1: "Комнаты могут быть назначены на классы как напрямую, так и посредством ",
                body_2: "Правил ",
                body_3: "в настройках типа занятия. Комнаты - это места, где вы проводите занятия. Они помогают вашим клиентам в ",
                body_4: "приложении OPEN Клиент ",
                body_5: "и фильтровать ваше расписание. Комнаты также важны, если вы хотите предотвратить перебронирование или использовать функцию списка ожидания в приложении OPEN Клиент. Обе функции зависят от вместимости комнат.",
            },
            pp: {
                body_1: "Для того, чтобы ваш профиль появился в приложении OPEN Client, необходимо заполнить Публичный профиль. Если все необходимые поля заполнены должным образом, то вскоре после подтверждения в приложении OPEN Клиент появится ваша запись."
            },
            online_tickets: {
                title: "Онлайн продажа абонементов",
                body_1: "OPEN предлагает Вам возможность продавать абонементы Вашим клиентам посредством ",
                body_2: "приложения OPEN Клиент ",
                body_3: "через оплату кредитными и дебетовыми картами. После покупки абонемента вашим клиентом он автоматически регистрируется в вашей базе данных и может быть использован для регистрации в классы. Для того, чтобы воспользоваться этой функцией, вам необходимо подключить свой аккаунт ",
                body_4: "Stripe ",
                body_5: "к OPEN в публичном профиле. Stripe - это платформа обработки платежей. Пожалуйста, обратите внимание, что за каждую транзакцию взимается комиссия в размере 3,5%, которая будет вычтена из платежей. Предполагаемые сборы для каждого типа абонементов вы можете найти в разделе Онлайн Продажи. Если тип абонемента не отмечен как Публичный, он не будет виден вашим клиентам в приложении OPEN Клиент.",
            },
            client_app: {
                title: "Клиентское приложение",
                body_1: "Приложение OPEN Клиент предлагает вам возможность поддерживать связь с вашими клиентами. В клиентском приложении они могут получать последние обновления вашего расписания, получать уведомления о предстоящих событиях, покупать абонементы и бронировать классы или присоединяться к спискам ожидания. Все настройки, связанные с клиентским приложением, вы можете найти в Бизнес-профиле -> Публичный профиль.",
                body_2: "iOS приложение",
                body_3: "Android приложение",
            },
            memberships: {
                title: "Клиентские Подписки",
                body_1: "Подписка - это абонентская услуга, которую вы можете продавать своим клиентам через приложение OPEN Client. Стоимость подписки будет автоматически списываться с кредитной карты клиента ежемесячно, начиная со дня покупки. Когда Вы создадите Подписку, она будет отображаться в панели управления Stripe в разделе Продукты. Вы можете найти все приобретенные подписки в панели управления Stripe в разделе Клиенты -> Подписки, где Вы можете сделать возврат денег, отмену подписки и даже временно приостановить платежи. Каждая транзакция Stripe (как и покупка членства) сопровождается комиссией, рассчитанной в соответствии с вашим регионом и типом кредитной карты, используемой для покупки, и обычно составляет от 1,4% + 0,25 до 2,9% + 0,25. Дополнительную информацию об услугах и комиссии Stripe вы можете найти на сайте www.stripe.com. Для того, чтобы начать создавать свои Подписки, вы должны подключить ваш аккаунт Stripe к OPEN. Это позволит нам обрабатывать платежи от вашего имени.",
            }
        },
        notifications: {
            booking_title: "Новая запись: {0} @ {1}",
            unbooking_title: "Запись отменена: {0} @ {1}",
            new_client_title: "Новый клиент: {0}",
            new_wix_ticket_title: "WIX ticket: {0}",
            new_stripe_ticket_title: "STRIPE покупка: {0}",
            new_feedback: "Новый отзыв о: {0}",
        },
        cookies_warning: "Этот сайт использует куки-файлы для улучшения работы пользователей.",
        cookies_ok: "Хорошо",
        scanning_expl: "Вы можете сканировать QR-коды клиентов, чтобы быстро зарегистрировать их на занятие вместо того, чтобы вводить их имена. Вы можете использовать любое внешнее сканирующее устройство, совместимое с вашим компьютером. Сканер должен работать в режиме Клавиатуры (HID-KBW). Ваши клиенты могут найти свои QR-коды в приветственном и-мейле, в приложении OPEN Client или вы можете отправить его им со страницы профиля клиента.",
        scan_state_scanning: "Cканирую...",
        scan_state_processing: "Обработка...",
        scan_state_scanning_expl: "Сканирование в процессе (пожалуйста, убедитесь, что ваш сканер подключен к компьютеру).",
        wrong_qr_code: "QR-код не был распознан",
        send_qr_to_client: "Отправить QR-code",
        send_qr_to_client_expl: "Ваши клиенты могут зарегистрироваться на занятия с помощью QR-кода. Они могут найти свои QR-коды в приветственном и-мейле, в приложении OPEN Client или вы можете отправить его им, нажав кнопку ОТПРАВИТЬ.",
        settings: "Настройки",
        send_welcome_email: "Автоматически отправлять приветственное письмо клиентам",
        send_welcome_email_expl: "При создании новой клиентской записи или обновлении адреса электронной почты клиент получает электронное письмо с кодом приглашения для регистрации в приложении OPEN Client и QR-кодом для быстрой регистрации на занятия.",
        send_exp_ticket_email: "Автоматически отправлять клиентам оповещения об истечении срока действия абонементов",
        send_exp_ticket_email_expl: "Ваши клиенты получат уведомление об истечении срока действия их абонементов.",
        welcome_email_custom_message: "Ваше сообщение",
        welcome_email_custom_message_expl: "Вы можете добавить короткое сообщение здесь и оно будет включено в приветственное письмо.",
        verification_sent: "На ваш электронный адрес было отправлено письмо со ссылкой для проверки. Если Вы не получили его, пожалуйста, убедитесь, что Ваш электронный адрес правильно написан и может получать электронную почту или свяжитесь со службой поддержки OPEN.",
        select_all: "Выбрать все",
        deselect_all: "Очистить все",
        buy: "Оплатить",
        feedback: "Отзывы",
        teacher_sessions: "Рабочее время учителей",
        feedback_expl: "Ваши клиенты могут оставлять свои отзывы о ваших занятиях через приложение Open Client.",
        teacher_sessions_expl: "Учителя могут сканировать свои QR-коды в разделе \"АвтоРегистрация\" для регистрации начала и окончания рабочего времени. Здесь вы найдете отчеты о рабочем времени учителей.",
        copy: "Копировать",
        message_sent: "Ваше сообщение было успешно отправлено",
        enabled: "Включено",
        contacts: "Контактная информация",
        getInTouch: "Свяжитесь с нами",
        home: "Начало",
        client_profile_updates: "Изменения, внесенные здесь, затронут только ваш профиль клиента. Ваш бизнес-профиль и/или профиль преподавателя не будут обновлены.",
        purchase_ticket: "Купить абонемент",
        confirm_cancellation: "Вы хотите отменить бронирование?",
    },
    no: {
        news_events: "Nyheter og arrangementer",
        in: "i",
        all: "Alle",
        go_to_discover: 'Gå til Discover',
        go_to: 'Go to ',
        processing_your_request: 'Processing your request',
        processing_payment: 'Processing payment...',
        open_motto: 'Find what\'s yours',
        back: 'Tilbake',
        next_month: 'Neste måned',
        current_month: 'Denne måneden',
        sign_in_title: 'Logg på kontoen din',
        register_title: 'Opprette ny konto',
        reset_title: 'Tilbakestill passordet ditt',
        token_title: 'Skriv inn gjenopprettingstokenet ditt',
        select: "Select",
        you: "Du",
        additional_email_address: "Additional email address",
        client_since: "Client since",
        name: "Navn",
        full_name: "Fullt navn",
        email: "Epostadresse",
        phone: 'Telefonnummer',
        address: 'Adresse',
        id_number: 'ID-nummer',
        payment_method: 'Betalingsmetode',
        payment_method_expl: "Bank account number or credit card number",
        injury_info: 'Skader',
        injury_info_expl: "Injury info is visible to the teachers in class overview",
        birthday: 'Birthday',
        note: 'Note',
        note_expl: 'A short note',
        notes: 'Notes',
        no_info: 'No info',
        search_or_add: 'Search or add',
        add_to_class: 'Add client',
        clear_search: 'Clear search',
        create_new: 'Create new',
        scan_qr: 'Scan',
        save: 'Lagre',
        address_expl: "Home address (for invoices)",
        day: "Dag",
        week: "Uken",
        month: "måned",
        six_months: "6 Months",
        year: "Year",
        cur_month: "Current month",
        close: "Close",
        continue: "Continue",
        cancel: "Cancel",
        search_results: "Search results",
        cancelled: "Cancelled",
        waiting: "Waiting List",
        export_data: "Export Data",
        send_message: "Send Message",
        message: "Message",
        send: "Send",
        view_profile: "View profile",
        back_to: "Back to",
        remove: "Remove",
        edit: "Edit",
        delete: "Delete",
        delete_account: "Slett konto",
        every_week: "Every week",
        even_weeks: "Even weeks",
        odd_weeks: "Odd weeks",
        from: "fra i",
        to: "To",
        until: "Until",
        add: "Add",
        edit_profile: "Edit Profile",
        edit_logo: "Upload logo",
        read_more: "Les mer",
        search: "Search",
        type_business: "Business/Teacher",
        type_client: "Client",
        payment_report: "Ticket sales by payment type",
        assign_rank: "Add client to group",
        assign_reward: "Add Reward",
        unassign_rank: "Remove client from group",
        rank_expl: "You can add clients to groups to add more structure to your client database. You can filter your database and reports and send messages to the groups. Group labels can be seen by your clients in the OPEN Client app.",
        reward_expl: "Rewards system is a motivational tool that allows you to manually or automatically assign progress badges to your clients. Build your own reward system, define rules and keep your clients engaged. Your clients will see their rewards in the Open Client app.",
        no_ranks_expl: "Looks like you haven't created Groups yet. Once you have at least one group you can add clients to it,use it in filters and send messages to the group members.",
        manage_ranks: "Manage groups",
        manage_rewards: "Manage rewards",
        group: "Group",
        add_new_rank: "Add new group",
        add_new_reward: "Add Reward",
        group_only: "Groups only",
        group_only_expl: "Only allow selected Group members to book classes of this type. You can manage your Groups in the Clients section.",
        select_groups: "Select groups",
        coupon_code: "Coupon code",
        show_all: "Show all",
        show_advanced: "Show advanced filters",
        hide_advanced: "Hide advanced filters",
        navigation: {
            dashboard: "Dashboard",
            profile: "Business profile",
            client_overview: "Client overview",
            report: "Report",
            reports: "Reports",
            logout: "Logout",
            schedule: "Timeplan",
            all_classes: "Alle klasser",
            cabinet: "Cabinet",
            clients: "Clients",
            prev_day: "Previous day",
            next_day: "Next day",
            next_week: "Next week",
            prev_week: "Previous week",
            today: "I dag",
            earlier: "Tidligere",
            tomorrow: "I morgen",
            later: "Snart",
            go_to_profile: "Go to Profile",
            support: "Support",
            switch_business: "Switch business",
            documentation: "Documentation",
            mobile_app: "Download app",
            how_to_use: "How to use OPEN",
            download_open_app: "Download OPEN App",
            manage_subscription_expl: "You can manage your plan in Business Profile",
            edit_resources: "Edit resources",
            add_delete: "Add/Delete",
            schedule_app_expl: "* To get a better overview of your schedule please download the app or use OPEN from your desktop.",
            discover: "Discover",
            upcoming_classes: "Kommende klasser",
        },
        short_day: {
            mon: "Mon",
            tue: "Tue",
            wed: "Wed",
            thu: "Thu",
            fri: "Fri",
            sat: "Sat",
            sun: "Sun",
        },
        business: {
            business_type: "Business type",
            name: "Business/Teacher name",
            web: "Web address",
            city: "City",
            zipcode: "Postal code",
            country: "Country",
            main_locale: "Main locale",
            locale: "Locale",
            all_locales: "All Locales",
            locales: "Locales",
            locale_expl: "Locale is a place where you offer your services. If you run your business in several places you might want to create additional locales. Each locale can contain several rooms.",
            location: "Location",
            business_description_hint: "Short description of your business that will be visible to your clients in the OPEN Client app.",
            business_description: "Business description",
            yoga_studio: "Yoga studio",
            gym: "Gym",
            pilates: "Pilates",
            dance_studio: "Dance studio",
            school: "School",
            team: "Team",
            ma: "Martial Arts",
            teacher: "Teacher",
            teacher_locale: "Center teacher",
            admin_locale: "Center administrator",
            admin: "Administrator",
            currency: "Currency",
            time_format: "Time format",
            use_reminders: "Use payment reminders",
            use_reminders_expl: "If enabled you will get prompted to create an unpaid class reminder each time when a client without a ticket gets registered to the class.",
        },
        messages: {
            no_messages: "Det er ingen meldinger her ennå. Vær oppmerksom på at alle meldinger eldre enn 1 år blir slettet automatisk.",
            messages_expl: "Send og motta meldinger fra {0}.",
            view_messages: "Utsikt",
            messages: "Meldinger",
            close: "Lukk",
        },
        description: "Description",
        add_more: "Add more",
        ad_reqs: "Please add a banner image, title, description and dates before saving.",
        link: "Link",
        bio: "Bio",
        bio_expl: "Will be visible to your clients in the Open Client app",
        click_to_edit: "Click to edit",
        back_to_profile: "Back to Profile",
        prev: "Previous",
        next: "Next",
        date: "Date",
        date_end: "End date",
        time: "Time",
        salary: "Salary",
        send_message_hint: "Please add an email address to send messages",
        required: "Required",
        optional: "Optional",
        see_all: "See all",
        login: "Logg inn",
        exit: "Exit",
        register: "Registrere",
        forgot_password: "Glemt passord?",
        have_token: "Jeg har allerede min token",
        reset_password: "tilbakestille passord",
        password: "Passord",
        new_password: "Nytt passord",
        agree_with: "Jeg er enig med",
        privacy_policy: "Personvernerklæring",
        privacy_policy_simple: "Personvernerklæring",
        create_account: "Opprett konto",
        send_reset_token: "Send tilbakestillingstoken",
        send_reset_token_expl: "Du vil motta symbolet for tilbakestilling av passord på e-postadressen din.",
        enter_token: 'Skriv inn gjenopprettingstokenet ditt (sjekk e-post og søppelpostmappen).',
        reset_password_expl: "If provided token is valid your password will be reset and you will be redirected to your dashboard",
        reset_token: "Reset token",
        token: "Token",
        sign_in_expl: "Sign in with your Open account",
        open_expl_login: "Open Manager is a space for gym and fitness studio managers and teachers. Manage your resources, handle bookings and get insights on every aspect of your business.",
        welcome_to_open: "Welcome to OPEN",
        client_account_warning: "You are trying to login with your Open Client account. Please use OPEN Client app to sign in or create a new business profile.",
        open: {
            why_open: "At OPEN we believe that we live in the beautiful era\n" +
                "\n" +
                "So many things are optimised by machines for us, that we finally have a chance to focus on things we love. Times when management of even a small business required all your time and attention are over. Now we can rely on our small yet powerful helpers that are always by our hand and let us be better at what we do and not at computers or paper work.\n" +
                "\n" +
                "\n" +
                "You might remember the times when new business software implementation at a company meant several days of training and constant monitoring and dedicated workforce afterwords. Things are different now.\n" +
                "\n" +
                "\n" +
                "We know that Time is one of the most valuable things in our life. That's why we focus on saving as much of it for you as we can. Through automation and continuous learning.\n" +
                "\n" +
                "\n" +
                "We also know that every gym and studio are unique and we are we are ready to learn from each of you. We don't offer you a rigid set of features, we offer you an opportunity to get exactly what you need. We are open to you.",
            subscriptions_title: "Use Open for free or choose one of our plans",
            subscriptions_expl: "We offer a free limited usage and three plans to choose from.",
            about_open_link: "About OPEN",
            client_app_link: "OPEN Client app",
            welcome_text_1: "Thank you for choosing OPEN to help you manage your business!",
            welcome_text_2: "To start using OPEN we have to complete your Business Profile by creating Class Types, Ticket Types and Teacher profiles. These are the main resources that will help you manage your business and keep track of all activities.",
            download_client_app: "Download OPEN Client app to book classes and stay in touch with your gym.",
        },
        tutorial: {
            how_to_start: {
                title: "How to start",
                body_1: "To start using OPEN we have to complete your",
                body_2: "by creating",
                body_3: "profiles. These are the main resources that will help you manage your business and keep track of all activities.",
            },
            class_types: {
                title: "Class Types",
                body_1: "Class Types are templates for the classes that you will be adding to your schedule. They contain information like class title, duration, class appearance in the OPEN Client app and ",
                body_1_1: "Rules",
                body_2: ". You can also configure which tickets will be accepted for the classes of this type and create a list of ",
                body_3: "regular clients",
                body_4: ". Once you’ve created at least one class type you can navigate to the Schedule and start adding classes.",
            },
            rules: {
                title: "Rules",
                body_1: "The most powerful feature of Class types is Rules. If you mark any class type as regular you’ll be able to add rules to it. Rules define when classes should appear in your schedule along with teacher and room configuration. Setting up rules is as easy as setting up an alarm on your mobile. Once you’ve added at least one rule you can navigate to the Schedule and start adding classes for any period of time.",
            },
            regular_clients: {
                title: "Regular clients",
                body_1: "If you add a list of regular clients to a class type they will be automatically added to all future classes of this type. This can save time and help manage group classes.",
            },
            ticket_types: {
                body_1: "Ticket types contain descriptions of the tickets that you sell to your clients. All the information except its name can be adjusted at the moment of ticket registration. You can set the maximum number of ticket usage on a per-day or per-week basis. Tickets marked as Subscription will be automatically renewed one day before it expires.",
            },
            teachers: {
                body_1: "Teachers are the professionals that work with you and can be assigned to classes in your schedule. Teacher profile includes a salary calculator and an overview of the teacher’s classes for the selected period of time. Teachers marked as Public will be also visible in your profile in the",
                body_2: "OPEN Client",
                body_3: " along with the photo and bio.",
            },
            rooms: {
                body_1: "Rooms can be assigned to classes either directly or through the ",
                body_2: "Rules ",
                body_3: "in the class types settings. Rooms are places where you give classes and help your clients in the ",
                body_4: "OPEN Client app ",
                body_5: "and you can filter your schedule. Rooms are also important if you want to prevent overbooking or use the Waiting list feature in OPEN Client app. Both features depend on the room capacity property.",
            },
            pp: {
                body_1: "In order to get listed in the OPEN Client app you have to complete your public profile and turn the Public switch on. If all required fields are properly filled your business will appear in the OPEN Client app shortly after confirmation."
            },
            online_tickets: {
                title: "Online ticket sales",
                body_1: "OPEN offers you an opportunity to sell tickets to your clients via the ",
                body_2: "OPEN Client app ",
                body_3: "with credit and debit cards. Once a ticket is purchased by your client it gets automatically registered in your database and can be used for registration to the classes. In order to use this feature you have to connect your ",
                body_4: "Stripe ",
                body_5: "account to OPEN in Public Profile. Stripe is a payment processing platform that handles card payments. Please note that there’s a fee of 3.5% per transaction that will be subtracted from the payments. You can find estimated charges for each ticket type in the Online Payments section. If a ticket type is not marked as Public it won’t be visible in the OPEN Client app to your clients.",
            },
            client_app: {
                title: "Client app",
                body_1: "Open Client app offers you an opportunity to better connect with your clients. In the client app they can get the latest updates of your schedule, get notified about the upcoming events, purchase tickets and book classes or join waiting lists. All Client app related settings you can find in Business Profile -> Public Profile.",
                body_2: "iOS App",
                body_3: "Android App",
            },
            memberships: {
                title: "Client Memberships",
                body_1: "Membership is a subscription service that you can sell to your clients through the OPEN Client app. Membership price will be automatically charged from your client credit card on monthly basis starting with the day of purchase. When you create a Memberships it will appear in your Stripe dashboard under Products. You can find all purchased memberships in your Stripe dashboard under Customers -> Subscriptions where you can make refunds, subscription cancellations and even pause the charges temporarily. Every Stripe transaction (like Membership purchase) comes with a fee calculated based on your region and type of Credit Card used for purchase and usually is between 1.4% + €0.25 and 2.9% + €0.25. You can find more information about Stripe services and fees by visiting www.stripe.com. In order to start creating your Memberships you have to connect your Stripe account to OPEN. This will allows us to process the payments on your behalf.",
            }
        },
        notifications: {
            booking_title: "New booking: {0} @ {1}",
            unbooking_title: "Booking cancelled: {0} @ {1}",
            new_client_title: "New client: {0}",
            new_wix_ticket_title: "WIX ticket: {0}",
            new_stripe_ticket_title: "STRIPE ticket: {0}",
            new_feedback: "New feedback for: {0}",
        },
        cookies_warning: "This website uses cookies to enhance the user experience.",
        cookies_ok: "I understand",
        scanning_expl: "You can scan your client QR-codes to quickly register them to classes instead of typing in their names. You can use any external scanner device compatible with your computer. Scanner must work in the Keyboard mode (HID-KBW). Your clients can find their QR-codes in the welcome email, OPEN Client app or you can send it to them from the Client profile page.",
        scan_state_scanning: "Listening...",
        scan_state_processing: "Processing...",
        scan_state_scanning_expl: "Scanning in Progress (please make sure your scanner is connected to the computer)",
        wrong_qr_code: "QR-code wasn't recognized",
        send_qr_to_client: "Send QR-code",
        send_qr_to_client_expl: "Your clients can register to your classes with a QR-code. They can find their QR-codes in the welcome email, OPEN Client app or you can send it to them by pressing SEND button.",
        settings: "Settings",
        send_welcome_email: "Automatically send Welcome Email to clients",
        send_welcome_email_expl: "When you create a new client record or update your client email address, they receive an email with the invitation code for the registration in the OPEN Client app and a QR code for the quick check-in at your classes.",
        send_exp_ticket_email: "Automatically send expiring ticket alerts to clients",
        send_exp_ticket_email_expl: "Your clients will get notified about the expiration of their tickets.",
        welcome_email_custom_message: "Custom message",
        welcome_email_custom_message_expl: "You can add a short message here and it will be included in the welcome email.",
        verification_sent: "An email with verification link was sent to your email address. If you don't receive it please make sure your email address is correctly spelled and can receive emails or contact OPEN support.",
        select_all: "Select all",
        deselect_all: "Clear all",
        buy: "Buy",
        feedback: "Feedback",
        teacher_sessions: "Teacher shifts",
        feedback_expl: "Your clients can leave their feedback to your classes though the Open Client app.",
        teacher_sessions_expl: "Teachers can scan their QR-codes in the Check-In section to log the start and the end of the working hours. Here you will find reports on the Teacher working hours.",
        copy: "Copy",
        message_sent: "You message was successfully sent",
        enabled: "Enabled",
        contacts: "Kontakter",
        getInTouch: "Ta kontakt",
        home: "Home",
        client_profile_updates: "Changes made here will only affect your client profile. Your business profile and/or teacher profile won't get updated.",
        purchase_ticket: "Kjøp billett",
        confirm_cancellation: "Ønsker du å kansellere reservasjonen din?",
    },
    ca: {
        in: "a",
        news_events: "Notícies i Esdeveniments",
        all: "Tots",
        go_to_discover: 'Anar a Descobrir',
        go_to: 'Anar a ',
        processing_your_request: 'Tramitación de su petición...',
        processing_payment: 'Processament del pagament...',
        open_motto: 'Troba el que és teu',
        back: 'Tornar',
        next_month: 'Proper mes',
        current_month: 'Mes en curs',
        send: "Enviar",
        sign_in_title: 'Accés al seu compte',
        register_title: 'Crear un compte nou',
        reset_title: 'Restablir la contrasenya',
        token_title: 'Introduïu el vostre token de recuperació',
        select: "Seleccioneu",
        you: "Vostè",
        name: "Nom",
        email: "Correu electrònic",
        phone: 'Número de telèfon',
        address: 'Direcció',
        id_number: 'Número d\'ID',
        payment_method: 'Mètode de pagament',
        payment_method_expl: "Número de compte bancari o de targeta de crèdit",
        injury_info: 'Lesions',
        injury_info_expl: "La informació de les lesions és visible per als professors a la visió general de la classe",
        birthday: 'Aniversaris',
        save: 'Guardar',
        day: "Dia",
        week: "Setmana",
        month: "Mes",
        cancelled: "Cancel·lat",
        send_message: "Enviar missatge",
        message: "Missatge",
        delete_account: "Eliminar compte",
        from: "De",
        to: "Fins",
        add: "Afegir",
        read_more: "Llegir més...",
        navigation: {
            schedule: "Horari",
            all_classes: "Totes les Classes",
            upcoming_classes: "Properes classes",
            tomorrow: "Demà",
            later: "Aviat",
        },
        business: {
            yoga_studio: "Estudi de Ioga",
            gym: "Gimnàs",
            pilates: "Estudi de Pilates",
            dance_studio: "Escola de ball",
            school: "Escola",
            team: "Equip",
            ma: "Arts marcials",
            basketball: "Bàsquet",
        },
        messages: {
            no_messages: "Encara no hi ha missatges aquí. Tingueu en compte que tots els missatges de més d'1 any s'eliminen automàticament.",
            messages_expl: "Envia i rep missatges de {0}.",
            view_messages: "Veure",
            messages: "Missatges",
            close: "Tanca",
        },
        login: "Inicia sessió",
        exit: "Aturar sessió",
        register: "Registrar-se",
        forgot_password: "Heu oblidat la contrasenya?",
        have_token: "Ja tinc el meu token",
        reset_password: "Restablir la contrasenya",
        password: "Contrasenya",
        new_password: "Nova contrasenya",
        agree_with: "Estic dacord amb la",
        privacy_policy: "Política de privadesa",
        create_account: "Crear compte",
        send_reset_token: "Enviar fitxa de reinici",
        send_reset_token_expl: "Rebràs el teu token de restabliment de contrasenya a la teva adreça de correu electrònic",
        enter_token: 'Introdueix el teu token de recuperació (comprova el teu correu electrònic i la carpeta de correu brossa)',
        reset_password_expl: "Si la fitxa proporcionada és vàlida, la contrasenya serà restablida i seràs redirigit al teu tauler de comandaments",
        reset_token: "Token de restabliment",
        token: "Token",
        cookies_warning: "Este sitio web utiliza cookies para mejorar la experiencia del usuario.",
        cookies_ok: "Vale",
        settings: "Ajustaments",
        contacts: "Contactes",
        getInTouch: "Posa't en contacte",
        home: "Inici",
        client_profile_updates: "Els canvis realitzats aquí només afectaran el vostre perfil de client. El vostre perfil d'empresa i/o de professor no s'actualitzaran.",
        purchase_ticket: "Comprar un tiquet",
        confirm_cancellation: "Vols cancel·lar la teva reserva?",
    },
    it: {
        news_events: "Notizie ed Eventi",
        in: "in",
        all: "Tutti",
        go_to_discover: 'Vai a Scopri',
        go_to: 'Vai a ',
        processing_your_request: 'Elaborazione della richiesta',
        processing_payment: 'Elaborazione del pagamento...',
        open_motto: 'Trova quello che fa per te',
        back: 'Indietro',
        next_month: 'Mese successivo',
        current_month: 'Mese corrente',
        sign_in_title: 'Accedi al tuo account',
        register_title: 'Crea nuovo account',
        reset_title: 'Reimposta la password',
        token_title: 'Inserisci il tuo token di recupero',
        select: "Seleziona",
        you: "Tu",
        additional_email_address: "Indirizzo email aggiuntivo",
        client_since: "Cliente dal",
        name: "Nome e Cognome",
        full_name: "Nome e Cognome",
        email: "Indirizzo email",
        phone: 'Numero di telefono',
        address: 'Indirizzo',
        id_number: 'Codice fiscale / Numero ID',
        payment_method: 'Metodo di pagamento',
        payment_method_expl: "Numero di conto bancario o numero di carta di credito",
        injury_info: 'Infortuni',
        injury_info_expl: "Le informazioni sugli infortuni sono visibili agli insegnanti nella panoramica della classe",
        birthday: 'Data di nascita',
        note: 'Nota',
        note_expl: 'Una breve nota',
        notes: 'Note',
        no_info: 'Nessuna informazione',
        search_or_add: 'Cerca o aggiungi',
        add_to_class: 'Aggiungi cliente',
        clear_search: 'Cancella ricerca',
        create_new: 'Crea nuovo',
        scan_qr: 'Scansiona',
        save: 'Salva',
        address_expl: "Indirizzo di casa (per le fatture)",
        day: "Giorno",
        week: "Settimana",
        month: "Mese",
        six_months: "6 Mesi",
        year: "Anno",
        cur_month: "Mese corrente",
        close: "Chiudi",
        continue: "Continua",
        cancel: "Annulla",
        search_results: "Risultati della ricerca",
        cancelled: "Annullato",
        waiting: "Lista d'attesa",
        export_data: "Esporta dati",
        send_message: "Invia messaggio",
        message: "Messaggio",
        send: "Invia",
        view_profile: "Visualizza profilo",
        back_to: "Torna a",
        remove: "Rimuovi",
        edit: "Modifica",
        delete: "Elimina",
        delete_account: "Elimina account",
        every_week: "Ogni settimana",
        even_weeks: "Settimane pari",
        odd_weeks: "Settimane dispari",
        from: "Da",
        to: "A",
        until: "Fino a",
        add: "Aggiungi",
        edit_profile: "Modifica profilo",
        edit_logo: "Carica logo",
        read_more: "Leggi di più",
        search: "Cerca",
        type_business: "Attività/Insegnante",
        type_client: "Cliente",
        payment_report: "Vendite biglietti per tipo di pagamento",
        assign_rank: "Aggiungi cliente al gruppo",
        assign_reward: "Aggiungi Premio",
        unassign_rank: "Rimuovi cliente dal gruppo",
        rank_expl: "Puoi aggiungere i clienti a gruppi per strutturare meglio il tuo database clienti. Puoi filtrare il tuo database e i report e inviare messaggi ai gruppi. Le etichette dei gruppi possono essere viste dai tuoi clienti nell'app OPEN Client.",
        reward_expl: "Il sistema di premi è uno strumento motivazionale che ti permette di assegnare manualmente o automaticamente badge di progresso ai tuoi clienti. Costruisci il tuo sistema di premi, definisci le regole e mantieni i tuoi clienti coinvolti. I tuoi clienti vedranno i loro premi nell'app Open Client.",
        no_ranks_expl: "Sembra che non hai ancora creato Gruppi. Una volta che avrai almeno un gruppo potrai aggiungervi i clienti, usarlo nei filtri e inviare messaggi ai membri del gruppo.",
        manage_ranks: "Gestisci gruppi",
        manage_rewards: "Gestisci premi",
        group: "Gruppo",
        add_new_rank: "Aggiungi nuovo gruppo",
        add_new_reward: "Aggiungi Premio",
        group_only: "Solo gruppi",
        group_only_expl: "Permetti solo ai membri del Gruppo selezionato di prenotare lezioni di questo tipo. Puoi gestire i tuoi Gruppi nella sezione Clienti.",
        select_groups: "Seleziona gruppi",
        coupon_code: "Codice coupon",
        show_all: "Mostra tutto",
        show_advanced: "Mostra filtri avanzati",
        hide_advanced: "Nascondi filtri avanzati",
        navigation: {
            dashboard: "Dashboard",
            profile: "Profilo aziendale",
            client_overview: "Panoramica clienti",
            report: "Report",
            reports: "Report",
            logout: "Esci",
            schedule: "Programma",
            all_classes: "Tutte le classi",
            cabinet: "Archivio",
            clients: "Clienti",
            prev_day: "Giorno precedente",
            next_day: "Giorno successivo",
            next_week: "Settimana successiva",
            prev_week: "Settimana precedente",
            today: "Oggi",
            earlier: "Prima",
            tomorrow: "Domani",
            later: "Prossimamente",
            go_to_profile: "Vai al profilo",
            support: "Supporto",
            switch_business: "Cambia attività",
            documentation: "Documentazione",
            mobile_app: "Scarica app",
            how_to_use: "Come usare OPEN",
            download_open_app: "Scarica l'app OPEN",
            manage_subscription_expl: "Puoi gestire il tuo piano nel Profilo Aziendale",
            edit_resources: "Modifica risorse",
            add_delete: "Aggiungi/Elimina",
            schedule_app_expl: "* Per una migliore panoramica del tuo programma, scarica l'app o usa OPEN dal desktop.",
            discover: "Scopri",
            upcoming_classes: "Prossime lezioni",
        },
        short_day: {
            mon: "Lun",
            tue: "Mar",
            wed: "Mer",
            thu: "Gio",
            fri: "Ven",
            sat: "Sab",
            sun: "Dom",
        },
        business: {
            business_type: "Tipo di attività",
            name: "Nome attività/insegnante",
            web: "Indirizzo web",
            city: "Città",
            zipcode: "Codice postale",
            country: "Paese",
            main_locale: "Sede principale",
            locale: "Sede",
            all_locales: "Tutte le sedi",
            locales: "Sedi",
            locale_expl: "La sede è il luogo dove offri i tuoi servizi. Se gestisci la tua attività in più luoghi, potresti voler creare sedi aggiuntive. Ogni sede può contenere diverse sale.",
            location: "Posizione",
            business_description_hint: "Breve descrizione della tua attività che sarà visibile ai tuoi clienti nell'app OPEN Client.",
            business_description: "Descrizione attività",
            yoga_studio: "Studio yoga",
            gym: "Palestra",
            pilates: "Pilates",
            dance_studio: "Scuola di danza",
            school: "Scuola",
            team: "Team",
            ma: "Arti marziali",
            basketball: "Basket",
            teacher: "Insegnante",
            teacher_locale: "Insegnante del centro",
            admin_locale: "Amministratore del centro",
            admin: "Amministratore",
            currency: "Valuta",
            time_format: "Formato ora",
            use_reminders: "Usa promemoria pagamenti",
            use_reminders_expl: "Se abilitato, ti verrà richiesto di creare un promemoria per le lezioni non pagate ogni volta che un cliente senza biglietto si registra alla lezione.",
        },
        messages: {
            no_messages: "Non ci sono ancora messaggi qui. Si noti che tutti i messaggi più vecchi di 1 anno vengono eliminati automaticamente.",
            messages_expl: "Invia e ricevi messaggi da {0}.",
            view_messages: "Visualizza",
            messages: "Messaggi",
            close: "Chiudi",
        },
        description: "Descrizione",
        add_more: "Aggiungi altro",
        ad_reqs: "Aggiungi un'immagine banner, titolo, descrizione e date prima di salvare.",
        link: "Link",
        bio: "Bio",
        bio_expl: "Sarà visibile ai tuoi clienti nell'app Open Client",
        click_to_edit: "Clicca per modificare",
        back_to_profile: "Torna al Profilo",
        prev: "Precedente",
        next: "Successivo",
        date: "Data",
        date_end: "Data fine",
        time: "Ora",
        salary: "Stipendio",
        send_message_hint: "Aggiungi un indirizzo email per inviare messaggi",
        required: "Obbligatorio",
        optional: "Opzionale",
        see_all: "Vedi tutti",
        login: "Accedi",
        exit: "Esci",
        register: "Registrati",
        forgot_password: "Password dimenticata?",
        have_token: "Ho già il mio token",
        reset_password: "Reimposta password",
        password: "Password",
        new_password: "Nuova password",
        agree_with: "Accetto la",
        privacy_policy: "Informativa sulla Privacy",
        privacy_policy_simple: "Informativa sulla Privacy",
        create_account: "Crea account",
        send_reset_token: "Invia token di reset",
        send_reset_token_expl: "Riceverai il tuo token di reset della password al tuo indirizzo email",
        enter_token: 'Inserisci il tuo token di recupero (controlla la tua email e la cartella spam)',
        reset_password_expl: "Se il token fornito è valido la tua password verrà reimpostata e sarai reindirizzato alla tua dashboard",
        reset_token: "Token di reset",
        token: "Token",
        sign_in_expl: "Accedi con il tuo account Open",
        open_expl_login: "Open Manager è uno spazio per gestori di palestre e studi fitness e insegnanti. Gestisci le tue risorse, gestisci le prenotazioni e ottieni informazioni su ogni aspetto della tua attività.",
        welcome_to_open: "Benvenuto su OPEN",
        client_account_warning: "Stai cercando di accedere con il tuo account Open Client. Usa l'app OPEN Client per accedere o crea un nuovo profilo aziendale.",
        cookies_warning: "Questo sito web utilizza i cookie per migliorare l'esperienza utente.",
        cookies_ok: "Ho capito",
        settings: "Impostazioni",
        send_welcome_email: "Invia automaticamente email di benvenuto ai clienti",
        send_welcome_email_expl: "Quando crei un nuovo record cliente o aggiorni l'email del tuo cliente, riceveranno un'email con il codice di invito per la registrazione nell'app OPEN Client e un codice QR per il check-in rapido alle tue lezioni.",
        send_exp_ticket_email: "Invia automaticamente avvisi di scadenza biglietti ai clienti",
        send_exp_ticket_email_expl: "I tuoi clienti verranno avvisati della scadenza dei loro biglietti.",
        welcome_email_custom_message: "Messaggio personalizzato",
        welcome_email_custom_message_expl: "Puoi aggiungere un breve messaggio qui e sarà incluso nell'email di benvenuto.",
        tutorial: {
            how_to_start: {
                title: "Come iniziare",
                body_1: "Per iniziare a usare OPEN dobbiamo completare il tuo",
                body_2: "creando",
                body_3: "profili. Queste sono le risorse principali che ti aiuteranno a gestire la tua attività e tenere traccia di tutte le attività.",
            },
            class_types: {
                title: "Tipi di Lezione",
                body_1: "I Tipi di Lezione sono modelli per le lezioni che aggiungerai al tuo programma. Contengono informazioni come titolo della lezione, durata, aspetto della lezione nell'app OPEN Client e ",
                body_1_1: "Regole",
                body_2: ". Puoi anche configurare quali biglietti saranno accettati per le lezioni di questo tipo e creare una lista di ",
                body_3: "clienti regolari",
                body_4: ". Una volta che hai creato almeno un tipo di lezione puoi navigare al Programma e iniziare ad aggiungere lezioni.",
            },
            rules: {
                title: "Regole",
                body_1: "La funzionalità più potente dei Tipi di Lezione sono le Regole. Se segni un tipo di lezione come regolare potrai aggiungervi delle regole. Le regole definiscono quando le lezioni dovrebbero apparire nel tuo programma insieme alla configurazione dell'insegnante e della sala. Impostare le regole è facile come impostare una sveglia sul tuo cellulare. Una volta che hai aggiunto almeno una regola puoi navigare al Programma e iniziare ad aggiungere lezioni per qualsiasi periodo di tempo.",
            },
            regular_clients: {
                title: "Clienti regolari",
                body_1: "Se aggiungi una lista di clienti regolari a un tipo di lezione, verranno automaticamente aggiunti a tutte le future lezioni di questo tipo. Questo può far risparmiare tempo e aiutare a gestire le lezioni di gruppo.",
            },
            ticket_types: {
                body_1: "I tipi di biglietto contengono descrizioni dei biglietti che vendi ai tuoi clienti. Tutte le informazioni tranne il nome possono essere modificate al momento della registrazione del biglietto. Puoi impostare il numero massimo di utilizzi del biglietto su base giornaliera o settimanale. I biglietti contrassegnati come Abbonamento verranno automaticamente rinnovati un giorno prima della scadenza.",
            },
            teachers: {
                body_1: "Gli insegnanti sono i professionisti che lavorano con te e possono essere assegnati alle lezioni nel tuo programma. Il profilo dell'insegnante include un calcolatore di stipendio e una panoramica delle lezioni dell'insegnante per il periodo selezionato. Gli insegnanti contrassegnati come Pubblici saranno visibili anche nel tuo profilo in",
                body_2: "OPEN Client",
                body_3: " insieme alla foto e alla biografia.",
            },
            rooms: {
                body_1: "Le sale possono essere assegnate alle lezioni direttamente o attraverso le ",
                body_2: "Regole ",
                body_3: "nelle impostazioni dei tipi di lezione. Le sale sono i luoghi dove tieni le lezioni e aiuti i tuoi clienti nell'",
                body_4: "app OPEN Client ",
                body_5: "e puoi filtrare il tuo programma. Le sale sono anche importanti se vuoi prevenire il sovraffollamento o utilizzare la funzione Lista d'attesa nell'app OPEN Client. Entrambe le funzioni dipendono dalla proprietà capacità della sala.",
            },
            pp: {
                body_1: "Per essere elencato nell'app OPEN Client devi completare il tuo profilo pubblico e attivare l'interruttore Pubblico. Se tutti i campi richiesti sono compilati correttamente, la tua attività apparirà nell'app OPEN Client poco dopo la conferma."
            },
            online_tickets: {
                title: "Vendita biglietti online",
                body_1: "OPEN ti offre l'opportunità di vendere biglietti ai tuoi clienti tramite l'",
                body_2: "app OPEN Client ",
                body_3: "con carte di credito e debito. Una volta che un biglietto viene acquistato dal tuo cliente, viene automaticamente registrato nel tuo database e può essere utilizzato per la registrazione alle lezioni. Per utilizzare questa funzione devi collegare il tuo account ",
                body_4: "Stripe ",
                body_5: "a OPEN nel Profilo Pubblico. Stripe è una piattaforma di elaborazione pagamenti che gestisce i pagamenti con carta. Nota che c'è una commissione del 3,5% per transazione che verrà detratta dai pagamenti. Puoi trovare le commissioni stimate per ogni tipo di biglietto nella sezione Pagamenti Online. Se un tipo di biglietto non è contrassegnato come Pubblico non sarà visibile nell'app OPEN Client ai tuoi clienti.",
            },
            client_app: {
                title: "App Client",
                body_1: "L'app Open Client ti offre l'opportunità di connetterti meglio con i tuoi clienti. Nell'app client possono ottenere gli ultimi aggiornamenti del tuo programma, ricevere notifiche sugli eventi imminenti, acquistare biglietti e prenotare lezioni o unirsi alle liste d'attesa. Tutte le impostazioni relative all'app Client si trovano in Profilo Aziendale -> Profilo Pubblico.",
                body_2: "App iOS",
                body_3: "App Android",
            },
            memberships: {
                title: "Abbonamenti Cliente",
                body_1: "L'abbonamento è un servizio in abbonamento che puoi vendere ai tuoi clienti attraverso l'app OPEN Client. Il prezzo dell'abbonamento verrà addebitato automaticamente dalla carta di credito del tuo cliente su base mensile a partire dal giorno dell'acquisto. Quando crei un Abbonamento apparirà nella tua dashboard Stripe sotto Prodotti. Puoi trovare tutti gli abbonamenti acquistati nella tua dashboard Stripe sotto Clienti -> Abbonamenti dove puoi effettuare rimborsi, cancellazioni di abbonamenti e persino mettere in pausa gli addebiti temporaneamente. Ogni transazione Stripe (come l'acquisto di un Abbonamento) comporta una commissione calcolata in base alla tua regione e al tipo di Carta di Credito utilizzata per l'acquisto e di solito è tra 1,4% + €0,25 e 2,9% + €0,25. Puoi trovare maggiori informazioni sui servizi e le commissioni di Stripe visitando www.stripe.com. Per iniziare a creare i tuoi Abbonamenti devi collegare il tuo account Stripe a OPEN. Questo ci permetterà di elaborare i pagamenti per tuo conto.",
            },
        },
        notifications: {
            booking_title: "Nuova prenotazione: {0} @ {1}",
            unbooking_title: "Prenotazione cancellata: {0} @ {1}",
            new_client_title: "Nuovo cliente: {0}",
            new_wix_ticket_title: "Biglietto WIX: {0}",
            new_stripe_ticket_title: "Biglietto STRIPE: {0}",
            new_feedback: "Nuovo feedback per: {0}",
        },
        scanning_expl: "Puoi scansionare i codici QR dei tuoi clienti per registrarli rapidamente alle lezioni invece di digitare i loro nomi. Puoi utilizzare qualsiasi dispositivo di scansione esterno compatibile con il tuo computer. Lo scanner deve funzionare in modalità Tastiera (HID-KBW). I tuoi clienti possono trovare i loro codici QR nell'email di benvenuto, nell'app OPEN Client o puoi inviarli dalla pagina del profilo Cliente.",
        scan_state_scanning: "In ascolto...",
        scan_state_processing: "Elaborazione in corso...",
        scan_state_scanning_expl: "Scansione in corso (assicurati che lo scanner sia collegato al computer)",
        wrong_qr_code: "Codice QR non riconosciuto",
        send_qr_to_client: "Invia codice QR",
        send_qr_to_client_expl: "I tuoi clienti possono registrarsi alle tue lezioni con un codice QR. Possono trovare i loro codici QR nell'email di benvenuto, nell'app OPEN Client o puoi inviarli premendo il pulsante INVIA.",
        verification_sent: "Un'email con il link di verifica è stata inviata al tuo indirizzo email. Se non la ricevi, assicurati che il tuo indirizzo email sia scritto correttamente e possa ricevere email o contatta il supporto OPEN.",
        select_all: "Seleziona tutto",
        deselect_all: "Deseleziona tutto",
        buy: "Acquista",
        feedback: "Feedback",
        teacher_sessions: "Turni insegnante",
        feedback_expl: "I tuoi clienti possono lasciare il loro feedback alle tue lezioni attraverso l'app Open Client.",
        teacher_sessions_expl: "Gli insegnanti possono scansionare i loro codici QR nella sezione Check-In per registrare l'inizio e la fine dell'orario di lavoro. Qui troverai i report sulle ore di lavoro dell'Insegnante.",
        copy: "Copia",
        message_sent: "Il tuo messaggio è stato inviato con successo",
        enabled: "Abilitato",
        contacts: "Contatti",
        getInTouch: "Contattaci",
        home: "Home",
        client_profile_updates: "Le modifiche effettuate qui influenzeranno solo il tuo profilo cliente. Il tuo profilo aziendale e/o profilo insegnante non verranno aggiornati.",
        purchase_ticket: "Acquista Biglietto",
        confirm_cancellation: "Vuoi cancellare la tua prenotazione?"
    }
};
